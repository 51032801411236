import { AnimatePresence } from "framer-motion"
import React from "react"

import { GlobalProvider } from "./src/context/globalContext"

export const wrapRootElement = ({ element }) => {
  return <GlobalProvider>{element}</GlobalProvider>
}

export const wrapPageElement = ({ element }) => {
  return (
    <AnimatePresence initial={true} exitBeforeEnter>
      {element}
    </AnimatePresence>
  )
}
