// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-color-inspiration-js": () => import("./../../../src/pages/work/color-inspiration.js" /* webpackChunkName: "component---src-pages-work-color-inspiration-js" */),
  "component---src-pages-work-conductor-js": () => import("./../../../src/pages/work/conductor.js" /* webpackChunkName: "component---src-pages-work-conductor-js" */),
  "component---src-pages-work-dreamnest-js": () => import("./../../../src/pages/work/dreamnest.js" /* webpackChunkName: "component---src-pages-work-dreamnest-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-nomad-js": () => import("./../../../src/pages/work/nomad.js" /* webpackChunkName: "component---src-pages-work-nomad-js" */)
}

